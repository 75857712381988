import _ from 'lodash'

export const normalizeLocationData = (placeType, locationResult) => {
  if (locationResult.context) {
    const context = locationResult.context.find((c) => c.id.includes(placeType))
    if (context) {
      return placeType === 'country' ? context.short_code.toUpperCase() : context.text
    }
  }

  if (locationResult.place_type.includes(placeType)) {
    return placeType === 'country'
      ? locationResult.properties.short_code.toUpperCase()
      : locationResult.text
  }

  return null
}

export const getCountryImageUrl = (country = 'US') => {
  return `https://gembah-public-assets.s3.us-west-2.amazonaws.com/static/Country-Flags/${country}.svg`
}

export const normalizeStringCharsForUrl = (stringToModify) => {
  // we can add more chars later to this regex
  return stringToModify.replace(/%/g, '')
}

export const mediaHasAcceptedMimetype = (mimetype) => ['image/png', 'image/jpeg'].includes(mimetype)

export const valueLabelPairs = (labelsObject) =>
  _.map(labelsObject, (label, key) => ({ value: key, label }))

// inspired by https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}
