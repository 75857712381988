import { valueLabelPairs } from '@lib/dataUtils'

export const PROJECT_STATUS = {
  DESIGN_VIABILITY: 'design-viability',
  DESIGN_CONCEPTING: 'design-concepting',
  DESIGN_ENGINEERING: 'design-engineering',
  DESIGN_DISCOVERY_KICKOFF: 'design-discovery-kickoff',
  DISCOVERY_ONBOARDING: 'discovery-onboarding',
  DISCOVERY: 'discovery',
  RESEARCH_KICKOFF: 'research-kickoff',
  RESEARCH_IN_PROGRESS: 'research-in-progress',
  RESEARCH_COMPLETED: 'research-completed',
  RESEARCH_PRESENTED: 'research-presented',
  NEW_RFQ: 'new-rfq',
  SAMPLING: 'sampling',
  PRE_FLIGHT: 'pre-flight',
  PRODUCTION: 'production',
  SHIPPING: 'shipping',
  FULFILLED: 'fulfilled',
  REORDER: 'reorder',
  ON_HOLD: 'on-hold',
  ARCHIVED: 'archived',
  // The states below are soft-deprecated: DO NOT remove these, as there may be some
  // legacy projects in these states, or active projects in these states but not yet updated.
  DESIGN_QUOTE: 'design-quote',
  DESIGN: 'design',
  PRE_PRODUCTION: 'pre-production',
  PRICE_QUOTE: 'price-quote',
  RESEARCH: 'research',
  NPI_NEW: 'new',
  NPI_ACTIVE: 'active',
}
export const PROJECT_STATUS_DISPLAY_VALUES = {
  [PROJECT_STATUS.DESIGN_VIABILITY]: 'Design Viability',
  [PROJECT_STATUS.DESIGN_CONCEPTING]: 'Design Concepting',
  [PROJECT_STATUS.DESIGN_ENGINEERING]: 'Design Engineering',
  [PROJECT_STATUS.DESIGN_DISCOVERY_KICKOFF]: 'Design/Discovery Kickoff',
  [PROJECT_STATUS.DISCOVERY_ONBOARDING]: 'Discovery Onboarding',
  [PROJECT_STATUS.DISCOVERY]: 'Discovery',
  [PROJECT_STATUS.RESEARCH_KICKOFF]: 'Research Kickoff',
  [PROJECT_STATUS.RESEARCH_IN_PROGRESS]: 'Research In Progress',
  [PROJECT_STATUS.RESEARCH_COMPLETED]: 'Research Completed',
  [PROJECT_STATUS.RESEARCH_PRESENTED]: 'Research Presented',
  [PROJECT_STATUS.NEW_RFQ]: 'New (Legacy)',
  [PROJECT_STATUS.NPI_NEW]: 'New (PDJ)',
  [PROJECT_STATUS.SAMPLING]: 'Sampling',
  [PROJECT_STATUS.PRE_FLIGHT]: 'Pre-Flight',
  [PROJECT_STATUS.NPI_ACTIVE]: 'PDJ - Active',
  [PROJECT_STATUS.PRODUCTION]: 'Production',
  [PROJECT_STATUS.SHIPPING]: 'Shipping',
  [PROJECT_STATUS.FULFILLED]: 'Fulfilled',
  [PROJECT_STATUS.REORDER]: 'Reorder',
  [PROJECT_STATUS.ON_HOLD]: 'On Hold',
  [PROJECT_STATUS.ARCHIVED]: 'Archived',
  // The states below are soft-deprecated: DO NOT remove these, as there may be some
  // legacy projects in these states, or active projects in these states but not yet updated.
  [PROJECT_STATUS.DESIGN_QUOTE]: 'Quoting',
  [PROJECT_STATUS.DESIGN]: 'Design',
  [PROJECT_STATUS.PRE_PRODUCTION]: 'Pre-Production',
  [PROJECT_STATUS.PRICE_QUOTE]: 'Price Quote',
  [PROJECT_STATUS.RESEARCH]: 'Research',
}
export const DEPRECATED_PHASE_STATUSES = [
  PROJECT_STATUS.DESIGN_QUOTE,
  PROJECT_STATUS.DESIGN,
  PROJECT_STATUS.PRE_PRODUCTION,
  PROJECT_STATUS.PRICE_QUOTE,
  PROJECT_STATUS.RESEARCH,
]
export const PROJECT_STATUS_OPTIONS = valueLabelPairs(PROJECT_STATUS_DISPLAY_VALUES)
export const PROJECT_STAFF_USER_TYPE = {
  ACCOUNT_MANAGER: 'account-manager',
  SALES_MANAGER: 'sales-manager',
  PROJECT_MANAGER: 'project-manager',
  DESIGN_MANAGER: 'design-manager',
  STAFF: 'staff', // Default value for deprecating ProjectStaffUserType PROD-1109
}
export const PROJECT_STAFF_USER_TYPE_DISPLAY_VALUES = {
  [PROJECT_STAFF_USER_TYPE.ACCOUNT_MANAGER]: 'Account Manager',
  [PROJECT_STAFF_USER_TYPE.SALES_MANAGER]: 'Sales Manager',
  [PROJECT_STAFF_USER_TYPE.PROJECT_MANAGER]: 'Project Manager',
  [PROJECT_STAFF_USER_TYPE.DESIGN_MANAGER]: 'Design Manager',
  [PROJECT_STAFF_USER_TYPE.STAFF]: 'Staff',
}
export const PROJECT_CONTRACTOR_TYPE = {
  DESIGNER: 'designer',
  ENGINEER: 'engineer',
  MECHANICAL_ENGINEER: 'mechanical-engineer',
  ELECTRICAL_ENGINEER: 'electrical-engineer',
}
export const PROJECT_CONTRACTOR_TYPE_DISPLAY_VALUES = {
  [PROJECT_CONTRACTOR_TYPE.DESIGNER]: 'Designer',
  [PROJECT_CONTRACTOR_TYPE.ENGINEER]: 'Engineer',
  [PROJECT_CONTRACTOR_TYPE.MECHANICAL_ENGINEER]: 'Mechanical Engineer',
  [PROJECT_CONTRACTOR_TYPE.ELECTRICAL_ENGINEER]: 'Electrical Engineer',
}
export const PROJECT_INDUSTRY = {
  NONE: 'none',
  AGRICULTURE: 'agriculture',
  APPAREL: 'apparel',
  BANKING: 'banking',
  BIOTECH: 'biotechnology',
  CHEMICALS: 'chemicals',
  COMMUNICATIONS: 'communications',
  CONSTRUCTION: 'construction',
  CONSULTING: 'consulting',
  EDUCATION: 'education',
  ELECTRONICS: 'electronics',
  ENERGY: 'energy',
  ENGINEERING: 'engineering',
  ENTERTAINMENT: 'entertainment',
  FINANCE: 'finance',
  FOOD_AND_BEVERAGE: 'food-and-beverage',
  GOVERNMENT: 'government',
  HEALTHCARE: 'healthcare',
  HOSPITALITY: 'hospitality',
  INSURANCE: 'insurance',
  MACHINERY: 'machinery',
  MANUFACTURING: 'manufacturing',
  MEDIA: 'media',
  NOT_FOR_PROFIT: 'not-for-profit',
  OTHER: 'other',
  RECREATION: 'recreation',
  RETAIL: 'retail',
  SHIPPING: 'shipping',
  TECHNOLOGY: 'technology',
  TELECOMMUNICATIONS: 'telecommunications',
  TRANSPORTATION: 'transportation',
  UTILITIES: 'utilities',
}
export const PROJECT_INDUSTRY_DISPLAY_VALUES = {
  [PROJECT_INDUSTRY.AGRICULTURE]: 'Agriculture',
  [PROJECT_INDUSTRY.APPAREL]: 'Apparel',
  [PROJECT_INDUSTRY.BANKING]: 'Banking',
  [PROJECT_INDUSTRY.BIOTECH]: 'Biotechnology',
  [PROJECT_INDUSTRY.CHEMICALS]: 'Chemicals',
  [PROJECT_INDUSTRY.COMMUNICATIONS]: 'Communications',
  [PROJECT_INDUSTRY.CONSTRUCTION]: 'Construction',
  [PROJECT_INDUSTRY.CONSULTING]: 'Consulting',
  [PROJECT_INDUSTRY.EDUCATION]: 'Education',
  [PROJECT_INDUSTRY.ELECTRONICS]: 'Electronics',
  [PROJECT_INDUSTRY.ENERGY]: 'Energy',
  [PROJECT_INDUSTRY.ENGINEERING]: 'Engineering',
  [PROJECT_INDUSTRY.ENTERTAINMENT]: 'Entertainment',
  [PROJECT_INDUSTRY.FINANCE]: 'Finance',
  [PROJECT_INDUSTRY.FOOD_AND_BEVERAGE]: 'Food & Beverage',
  [PROJECT_INDUSTRY.GOVERNMENT]: 'Government',
  [PROJECT_INDUSTRY.HEALTHCARE]: 'Healthcare',
  [PROJECT_INDUSTRY.HOSPITALITY]: 'Hospitality',
  [PROJECT_INDUSTRY.INSURANCE]: 'Insurance',
  [PROJECT_INDUSTRY.MACHINERY]: 'Machinery',
  [PROJECT_INDUSTRY.MANUFACTURING]: 'Manufacturing',
  [PROJECT_INDUSTRY.MEDIA]: 'Media',
  [PROJECT_INDUSTRY.NOT_FOR_PROFIT]: 'Not For Profit',
  [PROJECT_INDUSTRY.OTHER]: 'Other',
  [PROJECT_INDUSTRY.RECREATION]: 'Recreation',
  [PROJECT_INDUSTRY.RETAIL]: 'Retail',
  [PROJECT_INDUSTRY.SHIPPING]: 'Shipping',
  [PROJECT_INDUSTRY.TECHNOLOGY]: 'Technology',
  [PROJECT_INDUSTRY.TELECOMMUNICATIONS]: 'Telecommunications',
  [PROJECT_INDUSTRY.TRANSPORTATION]: 'Transportation',
  [PROJECT_INDUSTRY.UTILITIES]: 'Utilities',
}
export const PROJECT_INDUSTRY_OPTIONS = valueLabelPairs(PROJECT_INDUSTRY_DISPLAY_VALUES)
export const PROJECT_MERCHANDISING_CATEGORY = {
  APPLIANCES: 'appliances',
  ARTS_AND_CRAFTS: 'arts-and-crafts',
  AUDIO_EQUIPMENT: 'audio-equipment',
  AUTOMOTIVE_PARTS: 'automotive-parts-and-accessories',
  BABY: 'baby',
  BEAUTY_PERSONAL_CARE: 'beauty-and-personal-care',
  BOOKS: 'books',
  CELL_PHONES_AND_ACCESSORIES: 'cell-phones-and-accessories',
  CLOTHING_SHOES_JEWELRY: 'clothing-shoes-and-jewelry',
  COLLECTIBLES_FINE_ART: 'collectibles-and-fine-art',
  COMPUTERS_AND_ACCESSORIES: 'computers-and-accessories',
  ELECTRONICS: 'electronics',
  GARDEN_AND_OUTDOOR: 'garden-and-outdoor',
  HEALTH_AND_FITNESS: 'health-and-fitness',
  HOME_KITCHEN_HOUSEHOLD: 'home-kitchen-and-household-goods',
  INDUSTRIAL_AND_SCIENTIFIC: 'industrial-and-scientific',
  LUGGAGE_AND_TRAVEL_GEAR: 'luggage-and-travel-gear',
  LUXURY_BEAUTY: 'luxury-beauty',
  MUSIC_AND_ACCESSORIES: 'music-and-accessories',
  OFFICE_PRODUCTS: 'office-products',
  PET_SUPPLIES: 'pet-supplies',
  SPORTS_AND_OUTDOORS: 'sports-and-outdoors',
  TOOLS_HOME_IMPROVEMENT: 'tools-and-home-improvement',
  TOYS_AND_GAMES: 'toys-and-games',
  VEHICLES: 'vehicles',
  OTHER: 'other',
}
export const PROJECT_MERCHANDISING_CATEGORY_LABELS = {
  [PROJECT_MERCHANDISING_CATEGORY.APPLIANCES]: 'Appliances',
  [PROJECT_MERCHANDISING_CATEGORY.ARTS_AND_CRAFTS]: 'Arts & Crafts',
  [PROJECT_MERCHANDISING_CATEGORY.AUDIO_EQUIPMENT]: 'Audio Equipment',
  [PROJECT_MERCHANDISING_CATEGORY.AUTOMOTIVE_PARTS]: 'Automotive Parts & Accessories',
  [PROJECT_MERCHANDISING_CATEGORY.BABY]: 'Baby',
  [PROJECT_MERCHANDISING_CATEGORY.BEAUTY_PERSONAL_CARE]: 'Beauty & Personal Care',
  [PROJECT_MERCHANDISING_CATEGORY.BOOKS]: 'Books',
  [PROJECT_MERCHANDISING_CATEGORY.CELL_PHONES_AND_ACCESSORIES]: 'Cell Phones & Accessories',
  [PROJECT_MERCHANDISING_CATEGORY.CLOTHING_SHOES_JEWELRY]: 'Clothing, Shoes & Jewelry',
  [PROJECT_MERCHANDISING_CATEGORY.COLLECTIBLES_FINE_ART]: 'Collectibles & Fine Art',
  [PROJECT_MERCHANDISING_CATEGORY.COMPUTERS_AND_ACCESSORIES]: 'Computers & Accessories',
  [PROJECT_MERCHANDISING_CATEGORY.ELECTRONICS]: 'Electronics',
  [PROJECT_MERCHANDISING_CATEGORY.GARDEN_AND_OUTDOOR]: 'Garden & Outdoor',
  [PROJECT_MERCHANDISING_CATEGORY.HEALTH_AND_FITNESS]: 'Health & Fitness',
  [PROJECT_MERCHANDISING_CATEGORY.HOME_KITCHEN_HOUSEHOLD]: 'Home, Kitchen & Household Goods',
  [PROJECT_MERCHANDISING_CATEGORY.INDUSTRIAL_AND_SCIENTIFIC]: 'Industrial & Scientific',
  [PROJECT_MERCHANDISING_CATEGORY.LUGGAGE_AND_TRAVEL_GEAR]: 'Luggage & Travel Gear',
  [PROJECT_MERCHANDISING_CATEGORY.LUXURY_BEAUTY]: 'Luxury Beauty',
  [PROJECT_MERCHANDISING_CATEGORY.MUSIC_AND_ACCESSORIES]: 'Music & Accessories',
  [PROJECT_MERCHANDISING_CATEGORY.OFFICE_PRODUCTS]: 'Office Products',
  [PROJECT_MERCHANDISING_CATEGORY.PET_SUPPLIES]: 'Pet Supplies',
  [PROJECT_MERCHANDISING_CATEGORY.SPORTS_AND_OUTDOORS]: 'Sports & Outdoors',
  [PROJECT_MERCHANDISING_CATEGORY.TOOLS_HOME_IMPROVEMENT]: 'Tools & Home Improvement',
  [PROJECT_MERCHANDISING_CATEGORY.TOYS_AND_GAMES]: 'Toys & Games',
  [PROJECT_MERCHANDISING_CATEGORY.VEHICLES]: 'Vehicles',
  [PROJECT_MERCHANDISING_CATEGORY.OTHER]: 'Other',
}
export const PROJECT_MERCHANDISING_CATEGORY_OPTIONS = valueLabelPairs(
  PROJECT_MERCHANDISING_CATEGORY_LABELS
)
export const PROJECT_COMPLEXITY_CHOICES = {
  SIMPLE: 'simple',
  MEDIUM: 'medium',
  COMPLEX: 'complex',
}
export const PROJECT_COMPLEXITY_CHOICES_LABELS = {
  [PROJECT_COMPLEXITY_CHOICES.SIMPLE]: 'Simple',
  [PROJECT_COMPLEXITY_CHOICES.MEDIUM]: 'Medium',
  [PROJECT_COMPLEXITY_CHOICES.COMPLEX]: 'Complex',
}
export const PROJECT_COMPLEXITY_CHOICES_OPTIONS = valueLabelPairs(PROJECT_COMPLEXITY_CHOICES_LABELS)
export const PROJECT_REGION_CHOICES = {
  SA: 'sa',
  SNA: 'sna',
  EA: 'ea',
  SEA: 'sea',
  GLOBAL: 'global',
  OTHER: 'other',
  N_A: 'na',
}
export const PROJECT_REGION_CHOICES_LABELS = {
  [PROJECT_REGION_CHOICES.EA]: 'East Asia',
  [PROJECT_REGION_CHOICES.SEA]: 'Southeast Asia',
  [PROJECT_REGION_CHOICES.SA]: 'South Asia',
  [PROJECT_REGION_CHOICES.SNA]: 'South/North America',
  [PROJECT_REGION_CHOICES.GLOBAL]: 'Global',
  [PROJECT_REGION_CHOICES.OTHER]: 'Other',
  [PROJECT_REGION_CHOICES.N_A]: 'N/A',
}
export const PROJECT_REGION_CHOICES_OPTIONS = valueLabelPairs(PROJECT_REGION_CHOICES_LABELS)
export const PROJECT_RESOLUTION_TYPE_CHOICES = {
  JUNK: 'junk',
  LOST: 'lost',
  COMPLETED: 'completed',
  TERMINATED: 'terminated',
  DUPLICATE_REGION: 'duplicate_region',
}
export const PROJECT_RESOLUTION_TYPE_LABELS = {
  [PROJECT_RESOLUTION_TYPE_CHOICES.JUNK]: 'Junk',
  [PROJECT_RESOLUTION_TYPE_CHOICES.LOST]: 'Lost (Natural Churn)',
  [PROJECT_RESOLUTION_TYPE_CHOICES.COMPLETED]: 'Completed',
  [PROJECT_RESOLUTION_TYPE_CHOICES.TERMINATED]: 'Terminated (True Churn)',
  [PROJECT_RESOLUTION_TYPE_CHOICES.DUPLICATE_REGION]: 'Duplicate Region',
}
export const PROJECT_RESOLUTION_TYPE_CHOICES_OPTIONS = valueLabelPairs(
  PROJECT_RESOLUTION_TYPE_LABELS
)
export const CHECKLIST_FILTERS = {
  ALL: 'all',
  COMPLETED: 'completed',
  TODO: 'todo',
}
export const EMPTY_PDJ_FILTERS = {
  status: [],
  complexity: [],
  region: [],
  health: [],
  milestones: [],
}
export const PRESET_CHECKLISTS = {
  IN_REVIEW_PHASE: 'in-review-phase',
  PRICE_QUOTE_PHASE: 'price-quote-phase',
  DESIGN_PHASE: 'design-phase',
  SAMPLING_PHASE: 'sampling-phase',
  INVOICING_PHASE: 'invoicing-phase',
  MANAGEMENT_PHASE: 'management-phase',
  CONCISE_CHECKLIST: 'concise-checklist',
}
export const PRESET_CHECKLIST_LABELS = {
  [PRESET_CHECKLISTS.IN_REVIEW_PHASE]: 'In-Review Phase',
  [PRESET_CHECKLISTS.PRICE_QUOTE_PHASE]: 'Price Quote Phase',
  [PRESET_CHECKLISTS.DESIGN_PHASE]: 'Design Phase',
  [PRESET_CHECKLISTS.SAMPLING_PHASE]: 'Sampling Phase',
  [PRESET_CHECKLISTS.INVOICING_PHASE]: 'Invoicing Phase',
  [PRESET_CHECKLISTS.MANAGEMENT_PHASE]: 'Management Phase',
  [PRESET_CHECKLISTS.CONCISE_CHECKLIST]: 'Concise Checklist',
}
