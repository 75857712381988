import { ApolloProvider } from '@apollo/client'
import React from 'react'

import { USER_ID_SESSION_STORAGE_KEY } from '@lib/constants'
import tracking from '@lib/tracking'

import { createApolloClient } from './apollo/apolloUtils'

require('isomorphic-unfetch')

const IS_PROD = process.env.APP_ENV === 'prod'

let globalApolloClient = null

function getSwitchUserId() {
  const sessionStorageExists = typeof sessionStorage !== 'undefined'
  const localStorageExists = typeof localStorage !== 'undefined'

  if (!localStorageExists || !sessionStorageExists) {
    return undefined
  }

  const asParam = localStorage?.getItem(USER_ID_SESSION_STORAGE_KEY)

  if (asParam) {
    sessionStorage.setItem(USER_ID_SESSION_STORAGE_KEY, asParam)
    localStorage.removeItem(USER_ID_SESSION_STORAGE_KEY)
  }

  return sessionStorage.getItem(USER_ID_SESSION_STORAGE_KEY)
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
function initApolloClient(initialState, cookie = '') {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, cookie)
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    const switchedUserId = getSwitchUserId()

    globalApolloClient = createApolloClient(initialState, null, switchedUserId)
  }
  tracking.init(globalApolloClient)
  return globalApolloClient
}

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export default function withApollo(PageComponent) {
  // eslint-disable-next-line react/prop-types
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    const client = apolloClient || initApolloClient(apolloState)
    return (
      <ApolloProvider client={client}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PageComponent {...pageProps} />
      </ApolloProvider>
    )
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component'

    if (!IS_PROD && displayName === 'App') {
      // eslint-disable-next-line no-console
      console.warn('This withApollo HOC only works with PageComponents.')
    }

    WithApollo.displayName = `withApollo(${displayName})`
  }

  if (PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx) => {
      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      const cookie = ctx && ctx.req && ctx.req.headers && ctx.req.headers.cookie
      // This cookie voodoo is necessary so we have the cookie on SSR
      // https://github.com/apollographql/apollo-client/issues/5089#issuecomment-540605542
      // eslint-disable-next-line no-multi-assign
      const apolloClient = (ctx.apolloClient = initApolloClient({}, cookie))

      // Run wrapped getInitialProps methods
      let pageProps = {}
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx)
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract()

      return {
        ...pageProps,
        apolloState,
      }
    }
  }

  return WithApollo
}
