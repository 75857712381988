import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

import PopSelectField from '@forms/PopSelectField'

import { paginationLimit } from '@lib/apollo/apolloCache'
import { COMMON_WHITE } from '@lib/colors'

const useStyles = (shouldShowPagination) =>
  makeStyles((theme) => ({
    root: {
      alignItems: 'center',
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: shouldShowPagination ? 'space-between' : 'flex-end',
      '& .Mui-selected': {
        ...theme.palette.pop,
        color: COMMON_WHITE,
      },
      '& .MuiFormLabel-root': {
        display: 'none',
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    },
    rowsPerPageWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& fieldset': {
        border: 'none',
      },
      '& span': {
        fontSize: 14,
        marginRight: 10,
      },
    },
  }))

const PaginationView = ({
  onPageChange,
  totalPages,
  limit,
  limitValues,
  onLimitChange,
  page = 1,
  disabled = false,
  showFirstButton = true,
  showLastButton = true,
  shape = 'rounded',
  ...rest
}) => {
  const shouldShowPagination = totalPages > 1
  const classes = useStyles(shouldShowPagination)()

  return (
    <div className={classes.root} data-test-id="pagination-controls">
      {shouldShowPagination && (
        <Pagination
          data-test-id="pagination-controls-pages"
          onChange={onPageChange}
          count={totalPages}
          page={page}
          disabled={disabled}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          shape={shape}
          {...rest}
        />
      )}
      <div className={classes.rowsPerPageWrapper}>
        <span>Rows per page:</span>
        <PopSelectField
          style={{ border: 'none', fontSize: 14, padding: '10px 12px', height: 40, marginLeft: 10 }}
          value={paginationLimit()}
          onChange={onLimitChange}
        >
          {limitValues.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </PopSelectField>
      </div>
    </div>
  )
}

PaginationView.defaultProps = {
  limit: 0,
  page: 1,
  disabled: false,
  showFirstButton: true,
  showLastButton: true,
  shape: 'rounded',
  limitValues: [],
  onLimitChange: () => {},
}

PaginationView.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  limit: PropTypes.number,
  page: PropTypes.number,
  disabled: PropTypes.bool,
  showFirstButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
  shape: PropTypes.string,
  limitValues: PropTypes.arrayOf(PropTypes.number),
  onLimitChange: PropTypes.func,
}

export default PaginationView
