export const DEFAULT_ERR_MSG = 'An error has occurred. Please try again.'

export const TO_REDIRECT_ROUTE = 'to_redirect_route'

export const ERROR_WHITELIST = {
  not_found: '404',
  user_already_exists: 'server',
  invalid: 'server',
  invalid_credentials: 'Wrong username or password.',
  chat_channel_not_subscribed: 'server',
}

export const EXCEPT_QUERIES = ['UpdateUserPassword']

export const PAGE_QUERY_NAMES = ['GetProject', 'GetUser', 'GetCompany']
