import PropTypes from 'prop-types'
import React from 'react'

import { paginationLimit } from '@lib/apollo/apolloCache'
import tracking from '@lib/tracking'

import { LIMIT_PAGINATION_VALUES } from '@components_pop/Pagination/constants'

import PaginationView from './view'

const PaginationContainer = ({
  onChange,
  itemsCount,
  limit = LIMIT_PAGINATION_VALUES,
  ...rest
}) => {
  const processedTotalPages = itemsCount
    ? Math.ceil(parseInt(itemsCount, 10) / paginationLimit())
    : 0

  const handlePageChange = (e, page) => {
    e.preventDefault()
    onChange(page)
    tracking.trackEvent({
      id: 'pagination-page_click',
      toPage: page,
    })
  }

  const handleLimitChange = (e) => {
    paginationLimit(e.target.value)
    tracking.trackEvent({
      id: 'pagination-limit_click',
      limit: e.target.value,
    })
    onChange(1)
  }

  return (
    <PaginationView
      limitValues={limit}
      totalPages={processedTotalPages}
      onPageChange={handlePageChange}
      onLimitChange={handleLimitChange}
      {...rest}
    />
  )
}

PaginationContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  limit: PropTypes.number,
}

export default PaginationContainer
