import { makeVar, InMemoryCache } from '@apollo/client'
import moment from 'moment-timezone'

import { CHECKLIST_FILTERS, EMPTY_PDJ_FILTERS } from '@lib/constants/projects'
import { ACCOUNT_TYPES } from '@lib/userAuth'

import { DEFAULT_PAGINATION_LIMIT } from '@components_pop/Pagination'
import {
  DEFAULT_TABLES_SORT_COMPANY_FIELD,
  DEFAULT_TABLES_SORT_PROJECT_FIELD,
  DEFAULT_TABLES_SORT_USER_FIELD,
} from '@components_pop/Table/constants'

export const isInviteMembersModalOpen = makeVar(false)
export const isEditingJourneyTab = makeVar(false)
export const editingJourneyTabElementId = makeVar(null)
export const appErrors = makeVar([])
export const companiesSearch = makeVar('')
export const usersSearch = makeVar('')
export const projectsSearch = makeVar('')
export const projectsSearchDetails = makeVar('')
export const projectsProductionDashboardSearch = makeVar('')
export const projectsAmDashboardSearch = makeVar('')

// There have to be an initial sort value for the alphabetical
// sort to work initially
export const usersOrderBy = makeVar(DEFAULT_TABLES_SORT_USER_FIELD)
export const companiesOrderBy = makeVar(DEFAULT_TABLES_SORT_COMPANY_FIELD)
export const projectsAmDashboardOrderBy = makeVar(DEFAULT_TABLES_SORT_PROJECT_FIELD)
export const projectsOrderBy = makeVar(DEFAULT_TABLES_SORT_PROJECT_FIELD)
export const companiesFilterByType = makeVar([
  ACCOUNT_TYPES.CREATOR,
  ACCOUNT_TYPES.FACTORY,
  ACCOUNT_TYPES.EXPERT,
])
export const externalUsersFilter = makeVar(null)
export const internalUsersFilterByRole = makeVar(null)
export const internalUsersFilterByLocation = makeVar(null)
export const checklistFilter = makeVar(CHECKLIST_FILTERS.TODO)
export const checklistGroupsFilter = makeVar(CHECKLIST_FILTERS.ALL)
export const projectsStatusFilter = makeVar(null)
export const projectsStatusInFilter = makeVar([])
export const projectsHealthFilter = makeVar([])
export const projectsFetching = makeVar(false)
export const currentChatChannel = makeVar(null)
export const myProjectsFilter = makeVar(true)
export const usePop = makeVar(true)
export const featureFlags = makeVar({})
export const npiRoles = makeVar([])
export const notificationsWrapper = makeVar({ notifications: [], playedNotifications: {} })
export const paginationOffset = makeVar(0)
export const paginationLimit = makeVar(DEFAULT_PAGINATION_LIMIT)
export const paginationPage = makeVar(1)
export const projectFilters = makeVar(EMPTY_PDJ_FILTERS)
export const projectCategory = makeVar('NPI')
export const currentProject = makeVar(null)
export const currentProjectNpiSteps = makeVar(null)
export const currentUser = makeVar(null)
export const currentAccountUser = makeVar(null)
export const currentAccount = makeVar(null)
export const currentAccountUsers = makeVar(null)
export const currentAccountOwner = makeVar(null)
export const currentMilestone = makeVar(null)
export const confettiToss = makeVar(false)
export const confettiText = makeVar({ title: '', text: '' })
export const isDesktop = makeVar(false)
export const cachedToasts = makeVar([])
export const projectDetailsTabs = makeVar({ tab: {}, tabs: [] })
export const channelMediaHasChanged = makeVar(false)
export const clickedNotification = makeVar(null)
export const prefetchedChatChannels = makeVar([])

export const cache = new InMemoryCache({
  typePolicies: {
    ContractorType: {
      fields: {
        portfolioItems: {
          merge: false,
        },
      },
    },
    ProjectDetailsType: {
      keyArgs: ['id'],
      fields: {
        clientPulse: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        allChannelMessages: {
          keyArgs: ['channelId'],
          merge(existing, incoming, { readField }) {
            if (!existing && !incoming) {
              return {
                messages: [],
                haveOlderMessages: false,
              }
            }
            if (!existing || !existing.messages || existing.messages.length === 0) {
              return incoming
            }
            if (!incoming || !incoming.messages || incoming.messages.length === 0) {
              return existing
            }

            let firstMessageBatch
            let lastMessageBatch

            if (incoming.areNewMessages) {
              firstMessageBatch = existing.messages
              lastMessageBatch = incoming.messages
            } else {
              firstMessageBatch = incoming.messages
              lastMessageBatch = existing.messages
            }

            const merged = firstMessageBatch.reduce((all, curr) => {
              // eslint-disable-next-line no-underscore-dangle
              return { ...all, [curr.__ref]: curr }
            }, {})
            lastMessageBatch.forEach((msg) => {
              // eslint-disable-next-line no-underscore-dangle
              merged[msg.__ref] = msg
            })

            function compareMessages(a, b) {
              const aPostedAt = moment(readField('postedAt', a))
              const bPostedAt = moment(readField('postedAt', b))
              return aPostedAt.isBefore(bPostedAt) ? -1 : 1
            }

            const allMessages = Object.values(merged)
            allMessages.sort(compareMessages)

            return {
              messages: allMessages,
              haveOlderMessages: incoming.areNewMessages
                ? existing.haveOlderMessages
                : incoming.haveOlderMessages,
            }
          },
        },
        deletedMessages: {
          merge(_ignored, incomingMessageIds) {
            if (!incomingMessageIds) return []

            incomingMessageIds.forEach((id) => {
              cache.evict({ id: cache.identify({ __typename: 'MessageType', id }) })
            })
            return incomingMessageIds
          },
        },
        companiesSearch: {
          read() {
            return companiesSearch()
          },
        },
        isEditingJourneyTab: {
          read() {
            return isEditingJourneyTab()
          },
        },
        editingJourneyTabElementId: {
          read() {
            return editingJourneyTabElementId()
          },
        },
        companiesOrderBy: {
          read() {
            return companiesOrderBy()
          },
        },
        checklistGroupsFilter: {
          read() {
            return checklistGroupsFilter()
          },
        },
        companiesFilterByType: {
          read() {
            return companiesFilterByType()
          },
        },
        externalUsersFilter: {
          read() {
            return externalUsersFilter()
          },
        },
        internalUsersFilterByRole: {
          read() {
            return internalUsersFilterByRole()
          },
        },
        internalUsersFilterByLocation: {
          read() {
            return internalUsersFilterByLocation()
          },
        },
        usersSearch: {
          read() {
            return usersSearch()
          },
        },
        usersOrderBy: {
          read() {
            return usersOrderBy()
          },
        },
        projectsSearch: {
          read() {
            return projectsSearch()
          },
        },
        projectsSearchDetails: {
          read() {
            return projectsSearchDetails()
          },
        },
        featureFlags: {
          read() {
            return featureFlags()
          },
        },
        projectsProductionDashboardSearch: {
          read() {
            return projectsProductionDashboardSearch()
          },
        },
        projectsAmDashboardSearch: {
          read() {
            return projectsAmDashboardSearch()
          },
        },
        projectsAmDashboardOrderBy: {
          read() {
            return projectsAmDashboardOrderBy()
          },
        },
        projectsOrderBy: {
          read() {
            return projectsOrderBy()
          },
        },
        projectsStatusFilter: {
          read() {
            return projectsStatusFilter()
          },
        },
        projectsStatusInFilter: {
          read() {
            return projectsStatusInFilter()
          },
        },
        projectsHealthFilter: {
          read() {
            return projectsHealthFilter()
          },
        },
        projectsFetching: {
          read() {
            return projectsFetching()
          },
        },
        paginationOffset: {
          read() {
            return paginationOffset()
          },
        },
        paginationLimit: {
          read() {
            return paginationLimit()
          },
        },
        paginationPage: {
          read() {
            return paginationPage()
          },
        },
        projectFilters: {
          read() {
            return projectFilters()
          },
        },
        projectCategory: {
          read() {
            return projectCategory()
          },
        },
        myProjectsFilter: {
          read() {
            return myProjectsFilter()
          },
        },
        confettiToss: {
          read() {
            return confettiToss()
          },
        },
        confettiText: {
          read() {
            return confettiText()
          },
        },
        checklistFilter: {
          read() {
            return checklistFilter()
          },
        },
        currentChatChannel: {
          read() {
            return currentChatChannel()
          },
        },
        currentProject: {
          read() {
            return currentProject()
          },
        },
        currentProjectNpiSteps: {
          read() {
            return currentProjectNpiSteps()
          },
        },
        currentUser: {
          read() {
            return currentUser()
          },
        },
        usePop: {
          read() {
            return usePop()
          },
        },
        appErrors: {
          read() {
            return appErrors()
          },
        },
        isDesktop: {
          read() {
            return isDesktop()
          },
        },
        cachedToasts: {
          read() {
            return cachedToasts()
          },
        },
        projectDetailsTabs: {
          read() {
            return projectDetailsTabs()
          },
        },
        notificationsWrapper: {
          read() {
            return notificationsWrapper()
          },
          merge: false,
        },
        channelMediaHasChanged: {
          read() {
            return channelMediaHasChanged()
          },
        },
        clickedNotification: {
          read() {
            return clickedNotification()
          },
        },
        prefetchedChatChannels: {
          read() {
            return prefetchedChatChannels()
          },
        },
      },
    },
    Mutation: {
      fields: {
        trashMessage: {
          merge(_ignored, { trashedMessage }) {
            cache.evict({ id: cache.identify(trashedMessage) })
            cache.gc()
          },
        },
      },
    },
  },
})
