import { onError } from '@apollo/client/link/error'
import _ from 'lodash'
import router from 'next/router'
import React from 'react'

import { IS_PROD } from '@lib/constants'
import { PROJECT_STATUS_DISPLAY_VALUES } from '@lib/constants/projects'

import { appErrors } from './apolloCache'
import {
  DEFAULT_ERR_MSG,
  ERROR_WHITELIST,
  EXCEPT_QUERIES,
  PAGE_QUERY_NAMES,
  TO_REDIRECT_ROUTE,
} from './constants'

export default onError(({ operation, response, graphQLErrors, networkError }) => {
  let appErrorMessages = []
  if (graphQLErrors) {
    // console.log('operation:', operation) // TODO: log operation data and response message to Sentry
    _.forEach(graphQLErrors, (error) => {
      if (!error.code) {
        if (error?.message === 'Signature has expired') {
          appErrorMessages.push(error.message)
          window.location.href = '/logout'
          return
        }

        if (!appErrorMessages.includes(DEFAULT_ERR_MSG)) {
          appErrorMessages.push(DEFAULT_ERR_MSG)
        }
        return
      }

      if (error.code === 'invalid_user') {
        window.location.href = '/403'
      }

      if (error.code === 'not_logged_in') {
        appErrors([])
        if (typeof window !== 'undefined') {
          // saving this so we can use it later when the user is logged
          // only of the route is not logout / login
          if (!['/logout', '/login'].includes(router.route)) {
            localStorage.setItem(TO_REDIRECT_ROUTE, router.asPath)
          }
          window.location.href = '/login'
        }
        return
      }

      if (error.code === 'permission_denied') {
        appErrorMessages.push(error.message)
        // Only redirect to project list page when appropriate, not for every permission denied error.
        if (PAGE_QUERY_NAMES.includes(operation.operationName) && typeof window !== 'undefined') {
          window.location.href = '/'
        }
        return
      }

      if (error.code === 'token_invalid') {
        appErrorMessages.push(error.message)
        window.location.href = '/logout'
        return
      }

      if (error.code === 'status_deprecated') {
        if (PROJECT_STATUS_DISPLAY_VALUES[error.message]) {
          // eslint-disable-next-line max-len
          appErrorMessages.push(
            `The status ${
              PROJECT_STATUS_DISPLAY_VALUES[error.message]
            } is no longer in use. Please update the project to an appropriate status.`
          )
        } else {
          appErrorMessages.push(
            'The current project status is no longer in use. Please update the project to an appropriate status.'
          )
        }
        return
      }

      const errMsg = ERROR_WHITELIST[error.code]

      if (errMsg === '404') {
        // Only redirect to 404 page when appropriate, not for every 404.
        if (PAGE_QUERY_NAMES.includes(operation.operationName)) {
          window.location.href = '/404'
        }
        // Otherwise, 404s to be handled on a case-by-case basis by the component.
        return
      }

      if (EXCEPT_QUERIES.includes(operation.operationName)) return

      if (error.message && errMsg === 'server') {
        appErrorMessages.push(error.message)
      } else if (errMsg && errMsg !== 'server') {
        appErrorMessages.push(errMsg)
      } else {
        appErrorMessages.push(DEFAULT_ERR_MSG)
      }
    })
  }

  if (networkError) {
    // Eating network errors for polling queries; these are false positive errors.
    // Do not log Sentry errors for polling queries either, same reason as above.
    if (
      !['GetChatChannelMessages', 'GetUnclearedNotifications'].includes(operation.operationName)
    ) {
      appErrorMessages = [
        <>
          <div>An error has occurred.</div>
          <div>Possible Network Error detected.</div>
          <div>Please make sure you are online.</div>
        </>,
      ]
    }
  }
  appErrors([...appErrors(), ...appErrorMessages])

  if (!IS_PROD && graphQLErrors) {
    // eslint-disable-next-line no-console
    console.log('errorLink: operation', operation)
    // eslint-disable-next-line no-console
    console.log('errorLink: response:', response)
  }
  if (!IS_PROD && graphQLErrors) {
    // eslint-disable-next-line no-console
    console.log('graphQLErrors', graphQLErrors)
  }
  if (!IS_PROD && networkError) {
    // eslint-disable-next-line no-console
    console.log('networkError', networkError)
  }
})
