import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ...theme.formInputStyles,
}))

const PopSelectField = React.forwardRef((props, ref) => {
  const {
    error,
    label,
    id,
    helperText,
    FormLabelProps,
    children,
    inputProps = {},
    native = true,
    hasValidation = true,
    fullWidth,
    variant,
    customClasses = {},
    hasOptionalLabel,
    required,
    ...selectProps
  } = props
  const classes = useStyles()

  return (
    <FormControl variant={variant} fullWidth={fullWidth}>
      <FormLabel className={classes.label} htmlFor={id} error={error} {...FormLabelProps}>
        {label} {!!hasOptionalLabel && <span className={classes.optionalLabel}>Optional</span>}
        {!!required && <span>*</span>}
      </FormLabel>
      <Select
        native={native}
        inputRef={ref}
        className={clsx(classes.root, classes.input, customClasses)}
        id={id}
        error={error}
        classes={{ outlined: classes.root }}
        disableUnderline
        inputProps={{
          ...inputProps,
          className: classes.select,
        }}
        {...selectProps}
      >
        {children}
      </Select>
      {hasValidation && error && (
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
})

PopSelectField.defaultProps = { label: '', error: false, FormLabelProps: {} }

PopSelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  native: PropTypes.bool,
  required: PropTypes.bool,
  hasValidation: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  customClasses: PropTypes.object,
  FormLabelProps: PropTypes.object,
  children: PropTypes.node,
  inputProps: PropTypes.object,
  hasOptionalLabel: PropTypes.bool,
  // Essentially most props that MUI's TextField supports
}

export default PopSelectField
