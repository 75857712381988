import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import 'isomorphic-unfetch'

import { cache } from '@lib/apollo/apolloCache'

import apolloErrors from './apolloErrors'

function createIsomorphLink(cookie, switchedUserId) {
  const enhancedFetch = (url, init) => {
    const headers = {
      ...init.headers,
    }
    if (cookie) {
      headers.Cookie = cookie
    }

    if (switchedUserId) {
      headers['x-as'] = switchedUserId
    }

    return fetch(url, {
      ...init,
      headers,
    }).then((response) => response)
  }

  return new HttpLink({
    uri: `https://${process.env.APP_HOST}/api/query`,
    credentials: 'include',
    fetch: enhancedFetch,
  })
}

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
// eslint-disable-next-line import/prefer-default-export
export const createApolloClient = (initialState = {}, cookie, switchedUserId) => {
  const enhancedFetch = (url, init) =>
    fetch(url, {
      ...init,
      headers: {
        ...init.headers,
        cookie,
      },
    }).then((response) => response)

  cache.restore(initialState)

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    link: ApolloLink.from([apolloErrors, createIsomorphLink(cookie, switchedUserId)]),
    cache,
    fetch: enhancedFetch,
    resolvers: {},
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}
